var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('img', {
    staticStyle: {
      "margin-bottom": "20px"
    },
    attrs: {
      "src": require('@/assets/images/logo/viserx-logo.png'),
      "alt": "Logo",
      "height": "60"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }